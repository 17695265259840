import Vue from 'vue';
import { defineComponent, reactive, onBeforeMount, ref, watch } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import {
  ZidButton,
  ZidCard,
  ZidCardHeader,
  ZidTooltip,
  ZidIcon,
  ZidLoader,
  ZidInput,
  ZidModal,
  ZidBadge,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
} from '@zidsa/ui';
import router from '@/router/index';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { getApplicationsList } from '@/api/top-level-apis/dashboard/applicationsList';
import ApplicationModel from '@/api/models/ApplicationModel';
import dayjs from 'dayjs';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import RollbackIcon from '@/assets/icons/applications/RollbackIcon.vue';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import { ApplicationTypeProperty, ApplicationListingStatusProperty } from '@/api/models/ApplicationModel';
import { ShippingAppListingStatusProperty } from '@/api/models/ShippingApplicationModel';
import { AppListingStatusProperty } from '../../types/create-app/createApp.enum';
import { withdrawApplicationFromReview } from '../../api/withdrawAppFromReview';
import { AppTypeProperty } from '@/types/global-types/appTypes.enum';
import ErrorModel from '@/api/models/ErrorModel';

const { useGetters, useActions } = createNamespacedHelpers('applications');

export default defineComponent({
  components: {
    BaseText,
    ZidButton,
    ZidCard,
    ZidCardHeader,
    ZidTooltip,
    ZidIcon,
    ZidLoader,
    ZidModal,
    BaseIcon,
    ZidBadge,
    EditIcon,
    ZidInput,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
    SearchIcon,
    RollbackIcon,
  },
  setup() {
    const { filteredApplicationsList, applicationsList } = useGetters(['filteredApplicationsList', 'applicationsList']);
    const appsList = reactive<Record<string, ApplicationModel[]>>({ data: [] });
    const showAppSelectionModal = ref(false);
    const loadingAppsList = ref(false);
    const sortByStatusASC = ref(false);
    const sortByStatusDEC = ref(false);
    const searchInputValue = ref('');
    const DRAFT = null;
    const REQUEST_TO_PUBLISH = 1;
    const REJECTED = 2;
    const PUBLISHED = 3;
    const UN_PUBLISHED = 4;

    const { setApplicationsList, setSearchedApplicationName } = useActions([
      'setApplicationsList',
      'setSearchedApplicationName',
    ]);

    onBeforeMount(() => {
      loadApplicationsList();
    });

    const loadApplicationsList = async () => {
      loadingAppsList.value = true;
      const response = await getApplicationsList();
      if (response instanceof ErrorModel || !response.payload) {
        Vue.$toast.error('Failed to load Applications');
        loadingAppsList.value = false;
        return;
      }
      const { payload } = response;
      if (Array.isArray(payload) && payload.length > 0) {
        appsList.data = [...payload];
        setApplicationsList([...payload]);
      }
      loadingAppsList.value = false;
    };

    watch(filteredApplicationsList, () => {
      appsList.data = [...filteredApplicationsList.value];
    });

    watch(searchInputValue, (newSearchValue) => {
      setSearchedApplicationName(newSearchValue);
    });

    const onSearchInputChange = (changedValue: Record<string, string>) => {
      searchInputValue.value = changedValue.value;
    };

    const onSortByStatus = () => {
      const isAscending = !sortByStatusASC.value;

      appsList.data = appsList.data.sort((app) => {
        if (isAscending) {
          return app.listing_status === DRAFT ? -1 : 1;
        } else {
          return app.listing_status === PUBLISHED || app.listing_status === UN_PUBLISHED ? -1 : 1;
        }
      });

      sortByStatusASC.value = isAscending;
      sortByStatusDEC.value = !isAscending;
    };

    const onCreateAppClicked = () => {
      router.push(RoutingRouteEnum.CreateApplication).catch(() => {
        //
      });
    };

    const onCreateShippingAppClicked = () => {
      router.push(RoutingRouteEnum.CreateShippingApplication).catch(() => {
        //
      });
    };

    const canEditApp = (
      type: number,
      appListingStatus: AppListingStatusProperty | ShippingAppListingStatusProperty,
    ) => {
      if (type === ApplicationTypeProperty.Shipping) {
        return (
          appListingStatus !== ShippingAppListingStatusProperty.Published &&
          appListingStatus !== ShippingAppListingStatusProperty.RequestToPublish
        );
      } else {
        return (
          appListingStatus !== AppListingStatusProperty.Published &&
          appListingStatus !== AppListingStatusProperty.RequestToPublish
        );
      }
    };

    const canWithdrawApp = (
      type: number,
      appListingStatus: AppListingStatusProperty | ShippingAppListingStatusProperty,
    ) => {
      if (type === ApplicationTypeProperty.Shipping) {
        return appListingStatus === ShippingAppListingStatusProperty.RequestToPublish;
      } else {
        return appListingStatus === AppListingStatusProperty.RequestToPublish;
      }
    };

    const onEditClicked = (id: number, type: number) => {
      let selectedRoute = null;
      type === ApplicationTypeProperty.Shipping
        ? (selectedRoute = RoutingRouteEnum.CreateShippingApplication_StepsContainer)
        : (selectedRoute = RoutingRouteEnum.CreateApplication_StepsContainer);
      router.push({ name: selectedRoute, query: { appId: `${id}` } }).catch((err) => {
        //
      });
    };

    const onWithdrawAppClicked = async (id: number) => {
      const response = await withdrawApplicationFromReview(id);

      if (response.status !== 'success') {
        Vue.$toast.error("Couldn't withdraw this application from the review process.");
        return;
      }

      Vue.$toast.success('Application has been withdrawn from the review process');
      loadApplicationsList();
    };

    return {
      appsList,
      dayjs,
      DRAFT,
      REQUEST_TO_PUBLISH,
      REJECTED,
      PUBLISHED,
      UN_PUBLISHED,
      loadingAppsList,
      applicationsList,
      searchInputValue,
      sortByStatusDEC,
      sortByStatusASC,
      AppTypeProperty,
      AppListingStatusProperty,
      ApplicationTypeProperty,
      ApplicationListingStatusProperty,
      ShippingAppListingStatusProperty,
      showAppSelectionModal,
      canEditApp,
      canWithdrawApp,
      onCreateAppClicked,
      onEditClicked,
      onCreateShippingAppClicked,
      onWithdrawAppClicked,
      onSearchInputChange,
      onSortByStatus,
    };
  },
});
