import { computed, reactive } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { required, helpers, email } from '@vuelidate/validators';
import { APP_LANGUAGES } from '../dropdownInfo';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { AppGeneralDataInterface } from '../../../../store/modules/application/applicationState.type';
import { isValidURL } from '@/helpers/regex/URLs';

const { useGetters } = createNamespacedHelpers('application'); // module name

const useCreateApplication = () => {
  const { appGeneralData } = useGetters(['appGeneralData']);

  const createAppFormState = reactive<AppGeneralDataInterface>(
    JSON.parse(JSON.stringify(appGeneralData.value)) || {
      appNameArabic: '',
      appNameEnglish: '',
      appURL: '',
      appCategory: {
        label: '',
        value: '',
      },
      appLanguage: {
        label: '',
        value: '',
      },
      maintainerEmail: '',
    },
  );

  const rules = {
    appNameArabic: {
      required,
      appNameArabic: helpers.withMessage('من فضلك ادخل اسم التطبيق باللغة العربية والانجليزية', required),
      arabicContent: (value: string) => {
        return /^[\u0621-\u064A0-9 ]+$/.test(value);
      },
    },
    appNameEnglish: {
      required,
      appNameEnglish: helpers.withMessage('Application name is required in Arabic and English', required),
      englishContent: (value: string) => {
        return /^[A-Za-z0-9 _]*[A-Za-z]+[A-Za-z0-9 _]*$/.test(value);
      },
    },
    appURL: {
      required,
      appURL: helpers.withMessage('Application URL is required', (value: string) => {
        return isValidURL(value);
      }),
    },
    appCategory: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    appLanguage: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    maintainerEmail: {
      required,
      email,
      maintainerEmail: helpers.withMessage('E-mail is not valid', required),
    },
  };

  const appLanguages = reactive({
    options: APP_LANGUAGES,
  });

  const appCategories = reactive<Record<string, { label: string; value: number }[]>>({
    options: [],
  });

  const v$ = useVuelidate(rules, createAppFormState);

  const onInputDataChange = (
    changedValue: Record<string, string>,
    dataProperty: 'appNameArabic' | 'appNameEnglish' | 'appURL' | 'maintainerEmail',
  ) => {
    v$.value[dataProperty].$touch();
    createAppFormState[dataProperty] = changedValue.value;
  };

  const onSelectionDataChange = (changedValue: Record<string, string>, property: 'appCategory' | 'appLanguage') => {
    v$.value[property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    createAppFormState[property].label = selectedValue.label;
    createAppFormState[property].value = selectedValue.value;
  };

  const onCancelClicked = () => {
    createAppFormState.appNameArabic = '';
    createAppFormState.appNameEnglish = '';
    createAppFormState.appURL = '';
    createAppFormState.appCategory.label = '';
    createAppFormState.appCategory.value = '';
    createAppFormState.appLanguage.label = '';
    createAppFormState.appLanguage.value = '';
    createAppFormState.maintainerEmail = '';
  };

  const showMessageForArabicEnglishField = computed(() => {
    return (
      (v$.value.appNameArabic.$dirty || v$.value.appNameEnglish.$dirty) &&
      (v$.value.appNameArabic.$invalid || v$.value.appNameEnglish.$invalid)
    );
  });

  return {
    createAppFormState,
    appCategories,
    appLanguages,
    v$,
    showMessageForArabicEnglishField,
    onInputDataChange,
    onSelectionDataChange,
    onCancelClicked,
  };
};

export default useCreateApplication;
