import httpClient from '../../../api/httpClient';
import CategoriesResponseModel from '../models/CategoriesResponseModel';

const ENDPOINT_URL = '/market/partner-categories';

export const getPartnerCategories = () => {
  return httpClient
    .get(ENDPOINT_URL)
    .then((response) => new CategoriesResponseModel(response))
    .catch((error) => error);
};
