import httpClient from '../../../api/httpClient';
import CreateApplicationInformationModel from '../models/CreateApplicationInformationModel';
import CreateApplicationResponseModel from '../models/CreateApplicationResponseModel';

const ENDPOINT_URL = '/market/app/create';

export const createApplication = (applicationInfo: CreateApplicationInformationModel) => {
  return httpClient
    .post(ENDPOINT_URL, applicationInfo)
    .then((response) => new CreateApplicationResponseModel(response))
    .catch((error) => error);
};
