import BaseModel from '@/api/models/BaseModel';
import httpClient from '../../../api/httpClient';

const ENDPOINT_URL = '/market/app/update/listing/draft';

export const withdrawApplicationFromReview = (applicationID: number) => {
  return httpClient
    .post(`${ENDPOINT_URL}/${applicationID}`)
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
