import Vue from 'vue';
import { defineComponent, onBeforeMount } from '@vue/composition-api';
import BaseText from '../../../../components/text/BaseText.vue';
import { ZidButton, ZidIcon, ZidInput, ZidSelect, ZidSelectHeader, ZidSelectBody, ZidSelectOption } from '@zidsa/ui';
import useCreateApplication from '../../helpers/hooks/useCreateApplication';
import router from '../../../../router/index';
import { RoutingRouteEnum } from '../../../../router/routes.enum';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import TabMultiLanguage from '../../../../components/tab-multi-language/TabMultiLanguage.vue';
import { createApplication } from '../../api/createApplication';
import CreateApplicationInformationModel from '../../models/CreateApplicationInformationModel';
import { getPartnerCategories } from '../../api/partner-categories';
import PartnerCategoryModel from '../../models/PartnerCategoryModel';
import { AppTypesKeysEnum } from '@/types/global-types/appTypes.enum';

const { useActions: useApplicationActions } = createNamespacedHelpers('application');
const { useActions: useAuthActions } = createNamespacedHelpers('authentication');

export default defineComponent({
  components: {
    ZidButton,
    BaseText,
    ZidIcon,
    ZidInput,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    TabMultiLanguage,
  },
  setup() {
    const {
      createAppFormState,
      appCategories,
      appLanguages,
      v$,
      showMessageForArabicEnglishField,
      onInputDataChange,
      onSelectionDataChange,
      onCancelClicked,
    } = useCreateApplication();

    const { setTypeForApplicationBeingCreated, addStepCompleted, setAppGeneralData, setCurrentApplication } =
      useApplicationActions([
        'setTypeForApplicationBeingCreated',
        'addStepCompleted',
        'setAppGeneralData',
        'setCurrentApplication',
      ]);
    const { setPartnerCreatedAppType } = useAuthActions(['setPartnerCreatedAppType']);

    onBeforeMount(async () => {
      const response = await getPartnerCategories();
      if ('categories' in response) {
        appCategories.options = [];
        const { categories } = response;
        if (categories.length > 0) {
          categories.forEach((category: PartnerCategoryModel) => {
            appCategories.options.push({ label: category.name_en, value: category.id });
          });
        } else Vue.$toast.error('Categories is empty');
      } else {
        Vue.$toast.error('Failed to load categories');
        router.back();
      }
    });

    const onSubmit = async () => {
      const appDataState = {
        appURL: v$.value.appURL.$model,
        appCategory: v$.value.appCategory.$model,
        appLanguage: v$.value.appLanguage.$model,
        appNameArabic: v$.value.appNameArabic.$model,
        appNameEnglish: v$.value.appNameEnglish.$model,
        maintainerEmail: v$.value.maintainerEmail.$model,
      };
      const createApplicationInformation = new CreateApplicationInformationModel({
        name: v$.value.appNameEnglish.$model,
        name_ar: v$.value.appNameArabic.$model,
        url: v$.value.appURL.$model,
        app_category_id: v$.value.appCategory.value.$model,
        language: v$.value.appLanguage.value.$model,
        email: v$.value.maintainerEmail.$model,
        type: '0', // static temporarily
      });
      const response = await createApplication(createApplicationInformation);
      if ('payload' in response) {
        setPartnerCreatedAppType(AppTypesKeysEnum.PublicApps);
        setAppGeneralData(appDataState);
        setTypeForApplicationBeingCreated('normal'); // 'javascript' logic has been suspended for now
        addStepCompleted(RoutingRouteEnum.CreateApplication_GeneralSettings_Normal);
        Vue.$toast.success('Created application successfully');
        router
          .push({
            name: RoutingRouteEnum.CreateApplication_GeneralSettings_Normal,
            query: { appId: response.payload.id },
          })
          .catch(() => {
            //
          });
      } else {
        Vue.$toast.error('Failed to create application');
      }
    };

    return {
      createAppFormState,
      appCategories,
      appLanguages,
      v$,
      showMessageForArabicEnglishField,
      onInputDataChange,
      onSelectionDataChange,
      onCancelClicked,
      onSubmit,
    };
  },
});
