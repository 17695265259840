import Vue from 'vue';
import { defineComponent } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import {
  ZidButton,
  ZidIcon,
  ZidInput,
  ZidTextarea,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidFileSelector,
  ZidCheckbox,
} from '@zidsa/ui';
import useCreateApplication from '../helper/hooks/useCreateShippingApplication';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import CreateShippingApplicationInformationModel from '../models/CreateShippingApplicationInformationModel';
import { createShippingApplication } from '../api/createShippingApplication';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import { AppTypesKeysEnum } from '@/types/global-types/appTypes.enum';
import router from '@/router/index';
import { RoutingRouteEnum } from '@/router/routes.enum';
import ErrorModel from '@/api/models/ErrorModel';

const { useActions: useShippingActions } = createNamespacedHelpers('shipping');
const { useActions: useAuthActions } = createNamespacedHelpers('authentication');

export default defineComponent({
  components: {
    ZidButton,
    BaseText,
    ZidIcon,
    ZidInput,
    ZidTextarea,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidFileSelector,
    TabMultiLanguage,
    ZidCheckbox,
  },
  setup() {
    const {
      appId,
      createShippingAppFormState,
      v$,
      companyTypes,
      companyServices,
      isLogoValid,
      logoFileNameFromGlobal,
      showMessageForCompanyNameArEnField,
      showMessageForAboutArEnField,
      showMessageForMobileNumberField,
      showMessageForTypeOfCompanyField,
      showMessageForTechnicalSupportEmailField,
      CreateShippingAppSelectPropertyEnum,
      CreateShippingAppInputPropertiesEnum,
      isCheckboxChecked,
      onCheckBoxValueChange,
      onInputDataChange,
      onSelectionDataChange,
      onLogoSelection,
      onCancelClicked,
      onRemoveLogoClicked,
    } = useCreateApplication();

    const { setPartnerCreatedAppType } = useAuthActions(['setPartnerCreatedAppType']);
    const { addStepCompleted, setShippingAppGeneralData, setInProgressFullApplication } = useShippingActions([
      'addStepCompleted',
      'setShippingAppGeneralData',
      'setInProgressFullApplication',
    ]);

    const onSubmit = async () => {
      const shippingAppDataState = {
        appCompanyNameEnglish: v$.value.appCompanyNameEnglish.$model,
        appCompanyNameArabic: v$.value.appCompanyNameArabic.$model,
        companyURL: v$.value.companyURL.$model,
        aboutCompanyEnglish: v$.value.aboutCompanyEnglish.$model,
        aboutCompanyArabic: v$.value.aboutCompanyArabic.$model,
        companyLogo: v$.value.companyLogo.$model,
        mobileNumber: v$.value.mobileNumber.$model,
        technicalSupportEmail: v$.value.technicalSupportEmail.$model,
        typeOfCompany: v$.value.typeOfCompany.$model,
        services: v$.value.services.$model,
      };

      const createShippingApplicationInformation = new CreateShippingApplicationInformationModel({
        app_id: appId,
        name_en: createShippingAppFormState.appCompanyNameEnglish,
        name_ar: createShippingAppFormState.appCompanyNameArabic,
        url: createShippingAppFormState.companyURL,
        icon: createShippingAppFormState.companyLogo instanceof File ? createShippingAppFormState.companyLogo : null,
        mobile: createShippingAppFormState.mobileNumber,
        email: createShippingAppFormState.technicalSupportEmail,
        shipping_type: createShippingAppFormState.typeOfCompany.value,
        description_en: createShippingAppFormState.aboutCompanyEnglish,
        description_ar: createShippingAppFormState.aboutCompanyArabic,
        services: createShippingAppFormState.services,
      });

      const response = await createShippingApplication(createShippingApplicationInformation);
      if (response instanceof ErrorModel || !response.payload) {
        Vue.$toast.error('Failed to create shipping application');
        return;
      }
      const newFullApp = {
        payload: { ...response.payload },
      };
      setPartnerCreatedAppType(AppTypesKeysEnum.ShippingApps);
      setInProgressFullApplication(newFullApp);
      setShippingAppGeneralData(shippingAppDataState);
      addStepCompleted(RoutingRouteEnum.CreateShippingApplication_GeneralSettings);
      Vue.$toast.success('Shipping Application Is Created Successfully');
      router
        .replace({
          name: RoutingRouteEnum.CreateShippingApplication_StepsContainer,
          query: { appId: `${response.payload.app.app_id}` },
        })
        .catch(() => {
          //
        });
    };

    return {
      v$,
      appId,
      companyTypes,
      companyServices,
      isLogoValid,
      logoFileNameFromGlobal,
      showMessageForCompanyNameArEnField,
      showMessageForAboutArEnField,
      showMessageForMobileNumberField,
      showMessageForTypeOfCompanyField,
      showMessageForTechnicalSupportEmailField,
      CreateShippingAppSelectPropertyEnum,
      CreateShippingAppInputPropertiesEnum,
      isCheckboxChecked,
      onCheckBoxValueChange,
      onInputDataChange,
      onSelectionDataChange,
      onLogoSelection,
      onCancelClicked,
      onRemoveLogoClicked,
      onSubmit,
    };
  },
});
